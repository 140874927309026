import React from "react";
import NewsBG from "../assets/Interior2.jpg";
import { styled } from "@mui/system";
import { Typography, Container } from "@mui/material";
import { OrangeColor } from "../components/Const";
import Fade from "react-reveal/Fade";

function ConditiiColete() {
    const ConditiiColeteBackground = styled("div")({
        backgroundImage: `url(${NewsBG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        height: "auto",
        "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            zIndex: 1,
        },
    });
    return (
        <section>
            <ConditiiColeteBackground>
                <Container sx={{ position: "relative", zIndex: 2 }}>
                    <Fade top>
                        <Typography
                            textAlign="center"
                            variant="h2"
                            component="h3"
                            color={OrangeColor}
                            sx={{ justifyContent: "center", pt: 18 }}
                        >
                            Condiții Transport Colete
                        </Typography>
                    </Fade>
                </Container>
            </ConditiiColeteBackground>

        </section>
    );
}

export default ConditiiColete;

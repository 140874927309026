import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import News from "./News";
import Romania from "./Romania";
import Italia from "./Italia";
import About from "./About";
import Contact from "./Contact";
import Galerie from "../components/Galerie";
import Error from "./Error";
import ConditiiPersoane from "./ConditiiPersoane";
import ConditiiColete from "./ConditiiColete";
import DatePersonale from "./DatePersonale";

function Main({ height }) {
  const routes = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/About",
      element: <About />,
    },
    {
      path: "/Romania",
      element: <Romania />,
    },
    {
      path: "/Italia",
      element: <Italia />,
    },
    {
      path: "/News",
      element: <News />,
    },
    {
      path: "/conditii-transport-persoane",
      element: <ConditiiPersoane />,
    },
    {
      path: "/conditii-transport-colete",
      element: <ConditiiColete />,
    },
    {
      path: "/date-personale",
      element: <DatePersonale />,
    },
    {
      path: "/Galerie",
      element: <Galerie />,
    },
    {
      path: "/Contact",
      element: <Contact />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ];
  return (
    <div style={{ paddingTop: height }}>
      {" "}
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}{" "}
      </Routes>
    </div>
  );
}

export default Main;

import * as React from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CurseCraciun from "../assets/curse-craciun.jpeg";
import PromoVideo1 from '../assets/promovideo3.mp4'
import PromoVideo2 from '../assets/promovideo2.mp4'
import PromoVideo3 from '../assets/curse-craciun-video.mp4'


export default function CardNoutati() {
  return (
    <>
      <Card
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: 5,
          mt: 5,
        }}
      >
        <CardMedia
          component="img"
          image={CurseCraciun}
          alt="image"
          sx={{
            maxWidth: "60%",
            maxHeight: "60%",
            width: "100%",
            height: "auto",
          }}
        />
      </Card>
      <Card
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: 5,
          mt: 5,
        }}
      >
        <CardMedia
          component="video"
          src={PromoVideo3}
          alt="Promo Video"
          controls
          sx={{
            maxWidth: "60%",
            maxHeight: "60%",
            width: "60%",
            height: "auto",
          }}
        />
      </Card>
      <Card
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: 5,
          mt: 5,
        }}
      >
        <CardMedia
          component="video"
          src={PromoVideo1}
          alt="Promo Video"
          controls
          sx={{
            maxWidth: "50%",
            maxHeight: "50%",
            width: "60%",
            height: "auto",
          }}
        />
      </Card>
      <Card
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: 5,
          mt: 5,
        }}
      >
        <CardMedia
          component="video"
          src={PromoVideo2}
          alt="Promo Video"
          controls
          sx={{
            maxWidth: "100%",
            maxHeight: "100%",
            width: "100%",
            height: "auto",
          }}
        />
      </Card>
    </>
  );
}
